import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	addandbox,
} from "./action"
const store = configureStore({
	reducer: {
		login,
		addandbox,
	},
})
export default store