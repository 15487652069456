import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image, Space, Row, Col, Form, Typography, Card, Table, Dropdown} from 'antd'
import { ActionButton, ActivityLogModal, AddEditDrawer, AlertModal, FileEditModal, ImportDevice, IptvSearch, ModuleTopHeading } from '../../components';
import { DeleteOutlined, EditOutlined, FileDoneOutlined, FilterOutlined } from '@ant-design/icons';
import { TableLoader, actionsApi } from '../../shared';
const { Text } = Typography
const suffix = (
  <Image src='/assets/icons/search-ic.png' width={'14.86px'} preview={false} />
);


const AddEditBox = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { addboxDetail, loadingAddBoxDetail, filters, pagination } = useSelector(state => state?.addandbox)
  const [visible, setVisible]= useState(false)
  const [visiblemodal, setVisibleModal] = useState(false);
  const [ visiblelog, setVisibleLog ] = useState(false)
  const [ editmodal, setEditModal ] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [ deletebox, setDeleteBox ] = useState()
  const [ editbox, setEditBox ] = useState(null)
  const [ order, setOrder ] = useState(1)

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    console.log('Selected row id', newSelectedRowKeys)
  };

  useEffect(() => {
    dispatch(actionsApi?.getAddBoxDetail({ ...filters, ...pagination, pageNo: 1 }))
    form.setFieldsValue({
        ...filters,
    })
    setOrder(filters?.order)


}, [])
const filter = (order) => {
    let data = form.getFieldsValue()
    dispatch(actionsApi?.getAddBoxDetail({ ...data, order, ...pagination, pageNo: 1 }))
    dispatch(actionsApi?.changeAddboxDetailFilters({ ...data, order }))
}
const call = (pageNo = 1, pageSize = 10) => {
    dispatch(actionsApi?.getAddBoxDetail({ ...filters, pageNo, pageSize }))
}
const onClick = ({ key }) => {
    key = parseInt(key) + 1
    setOrder(key)
    filter(key)
};

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
        {
            key: '1',
            text: 'Edit',
            onSelect: () => {
                setEditModal(true)
            },
        },
        {
            key: '2',
            text: 'Delete',
            onSelect: () => {
                setEditModal(true)
            },
        },
    ],
  };

  
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
    ];


    
    


    const columns = [
        {
            title: <Text>CUSTOMER NAME</Text>,
            dataIndex: 'customerName',
            key: 'customerName',
            fixed:'left',
            width:150,
        },
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macAddress',
            key: 'macAddress',
        },
        {
            title: <Text>SERIAL NUMBER</Text>,
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: <Text>IP ADDRESS</Text>,
            dataIndex: 'ipaddress',
            key: 'ipaddress',
        },
        {
            title: <Text>TIME STAMP</Text>,
            dataIndex: 'timeStamp',
            key: 'timeStamp',
        },
        {
            title: <Text>URL's</Text>,
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: <Text>URL UPDATE</Text>,
            dataIndex: 'updateUrl',
            key: 'updateUrl',
        },
        {
            title: <Text>STATUS</Text>,
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                return (
                    status === 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>online</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>offline</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: <Text>ACTION</Text>,
            key: 'action',
            fixed:'right',
            width:100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit Box'
                        icon={<EditOutlined className='green-color'/>}
                        onClick={()=>{setVisible(true);setEditBox(row?.id)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color'/>}
                        onClick={()=>{setVisibleModal(true);setDeleteBox({id:row?.id, customerName: row?.customerName})}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
          },
      ];

  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col md={6}>
                    <ModuleTopHeading 
                        name='Add/Edit box'
                        onClick={()=>setVisible(true)}
                    />
                </Col>
                <Col lg={{span: 18}} md={{span: 18}} xs={{span: 24}} sm={{span: 24}}>
                    <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                        <IptvSearch
                            name="search"
                            size='large'
                            value={form.getFieldValue('search') || ''}
                            suffix={suffix}
                            placeholder='Search'
                        />
                        <Dropdown
                            menu={{
                            items
                            }}
                            trigger={['click']}
                            arrow
                            icon={<FilterOutlined />}
                        >
                            <Button
                                type='primary'
                                icon={<FilterOutlined/>}
                                size='large'
                            >
                                Filter
                            </Button>
                        </Dropdown>
                        <Button
                            type='primary'
                            icon={<FileDoneOutlined />}
                            size='large'
                            onClick={()=> setVisibleLog(true)}
                            className='btn-color-other'
                        >
                            Activity log
                        </Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        rowKey={(record) => record.id}
                        rowSelection={rowSelection}
                        columns={columns} 
                        dataSource={addboxDetail} 
                        style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                        scroll={{x: 1500}}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={
                            {
                                ...TableLoader,
                                spinning: loadingAddBoxDetail
                            }
                        }
                    />
                </Col>
            </Row>
        </Card>
        <ImportDevice />
        <AddEditDrawer
                visible={visible}
                editbox={editbox}
                getAllApplications={call}
                onClose={()=> {setVisible(false);setEditBox(null)}}
            />
        <ActivityLogModal 
            visiblelog = {visiblelog}
            onClose={()=>setVisibleLog(false)}
        />
        <FileEditModal 
            editmodal={editmodal}
            onClose={()=>setEditModal(false)}
        />
        <AlertModal
            visiblemodal={visiblemodal}
            deletebox={deletebox}
            getAllApplications={call}
            onClose={()=>{setVisibleModal(false);setDeleteBox(null)}}
        />
    </div>
  )
}

export {AddEditBox}