
//login
import { login, forgotPassword, loginResponse, loginClear, AuthCheck } from "./action/Login"
//dashboard
import { getAddBoxDetail, changeAddboxDetailFilters } from "./action/addandbox"


export const actionsApi = {
    login, 
    forgotPassword,
    loginResponse, 
    loginClear, 
    AuthCheck, 
    //addandroidbox 
    // getBoxStatistics,
    getAddBoxDetail, 
    changeAddboxDetailFilters,
}
