import React, { useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, theme, Select, Image, Typography} from "antd"
import { IptvInput } from "../input"
import { ActivityLogModal } from '../ActivityLogModal'
import './index.css'
import { FileDoneOutlined } from "@ant-design/icons"
import { ApiCalls } from "../../shared/ApiCalls"
import { Iptvselect } from "../Iptvselect"
import { methodUrlType, methodsUrl } from "../../shared/lookups"
const { Option } = Select;

const MasterPortalDrawer = ({visible, onClose}) => {
    const [form] = Form.useForm()
    const [ visiblelog, setVisibleLog ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ displayinputs, setDisplayInputs ] = useState(false)

    const InputDisplay = (e) => {
        setDisplayInputs(e==='3'? true : false)
    }
   


    const createMasterPortal = async(formData) => {
        setLoading(true)
        try{
            const result = await ApiCalls.MasterPortalApi.createMasterPortal(formData)
            setLoading(false);
            if(result){
                onClose();
                form.resetFields();
            }
        } catch(error){
            console.error('Error add master portal data', error)
        }
    }

    
    
    
    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            createMasterPortal(formData);
            setDisplayInputs(false);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  return (
    <div>
        <Drawer
            title='Master portal'
            onClose={onClose}
            open={visible}
            className="drawer-cs"
            width={700}
            extra={
                <Button
                    type='primary'
                    icon={<FileDoneOutlined />}
                    size='large'
                    onClick={()=> setVisibleLog(true)}
                    className='btn-color-other'
                >
                    Activity log
                </Button>
              }
              footer={
                <div className="w-100 pt-3 space-between-center">
                    <Space className="w-100">
                        <Button 
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            loading={loading}
                            onClick={()=>form.submit()}
                        >
                            Save
                        </Button>
                    </Space>
                    <Button  
                        type="primary"
                    >
                        Update to All devices
                    </Button>
                </div>
              }
        >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={16}>                       
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <Iptvselect
                                label= 'Select URL'
                                name="selectUrl" 
                                required
                                message='Please choose select url!'
                                placeholder='Select URL'
                                value={form.getFieldValue('selectUrl') || ''}
                                options={methodsUrl}
                            />  
                        </Col>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <Iptvselect 
                                label= 'URL Type'
                                name="urlType" 
                                required
                                message='Please choose url type!'
                                placeholder='Select URL Type'
                                value={form.getFieldValue('urlType') || ''}
                                onChange={InputDisplay}
                                options={methodUrlType}
                            />
                        </Col>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <IptvInput
                                name='url'
                                label='URL'
                                placeholder='e.g www.example.com'
                                required
                                message='Please add url'
                                value={form.getFieldValue('url')}
                            />
                        </Col>
                        {
                            displayinputs?
                                <>
                                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                        <IptvInput
                                            label='Username'
                                            name='userName'
                                            placeholder='e.g Jhone'
                                            required
                                            message='Please enter url'
                                            value={form.getFieldValue("userName") || ''}
                                        />
                                    </Col>
                                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                        <IptvInput
                                            label='Password'
                                            type='password'
                                            name='password'
                                            required
                                            message={()=>{}}
                                            placeholder='e.g A@123456'
                                            value={form.getFieldValue("password") || ''}
                                            rules={[
                                                {
                                                    required: true,
                                                }
                                            ]}
                                            validator={({ getFieldValue }) => ({
                                                validator: (_, value) => {
                                                    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                                    if (!reg.test(value)) {
                                                        return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })}
                                        />
                                    </Col>
                                </>
                            :
                            <></>
                        }
                    </Row>
                </Form>
        </Drawer>
        
        <ActivityLogModal
            visiblelog={visiblelog}
            onClose={()=>setVisibleLog(false)}
        />
        
    </div>
  )
}

export {MasterPortalDrawer}