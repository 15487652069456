import React from 'react'
import { Image, Space, Row, Col, Form, Typography, Dropdown, Card, Table, Button } from 'antd'
import { IptvSearch, ModuleTopHeading } from '../../components';
import { FilterOutlined } from '@ant-design/icons';

const { Text } = Typography
const suffix = (
    <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
  );

const TransactionReport = () => {
    const [form] = Form.useForm()

    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Most downloads</a>,
            key: '2',
        },
        {
            label: <a href="#">Less downloads</a>,
            key: '3',
        },
    ];
  
  
    const columns = [
        {
            title: <Text>BOX NAME</Text>,
            dataIndex: 'boxName',
            key: 'boxName',
        },
        {
            title: <Text>ACTIVITY</Text>,
            dataIndex: 'activity',
            key: 'activity',
        },
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macAddress',
            key: 'macAddress',
        },
        {
            title: <Text>TIME STAMP</Text>,
            dataIndex: 'timeStamp',
            key: 'timeStamp',
        },
        {
            title: <Text>VENDOR NAME</Text>,
            dataIndex: 'vendorName',
            key: 'vendorName',
        },
      ];
    const data = [
          {
              key: '1',
              boxName:   <Text>AD12</Text>,
              activity:   <Text>Box Added</Text>,
              macAddress:   <Text>00:B0:D0:63:C2:46</Text>,
              timeStamp:   <Text>23/03/2023 11:04</Text>,
              vendorName:   <Text>Asli Hayat</Text>,
          },
          {
            key: '2',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:46</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '3',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:46</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '4',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:46</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '5',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:46</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '6',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:46</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
      ];
  
    return (
        <div>
            <Card className='radius-12 border0'>
                <Row gutter={[24,24]}>
                    <Col md={12}>
                        <ModuleTopHeading 
                            name='Transaction Report'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                        <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                            <IptvSearch
                                name="search"
                                size='large'
                                value={form.getFieldValue('search') || ''}
                                suffix={suffix}
                                placeholder='Search'
                            />
                            <Dropdown
                                menu={{
                                items
                                }}
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                                <Button
                                    type='primary'
                                    icon={<FilterOutlined/>}
                                    size='large'
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                            scroll={{x: 1000}}
                            pagination={{ 
                                hideOnSinglePage: true, 
                                total: 12,
                                // total: pagination?.totalRecords,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions:['20', '50', '100'],
                                //onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                                showTotal: total => <Button>Total: {total}</Button>
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export {TransactionReport}