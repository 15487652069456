import { message } from "antd";
import { domainUrl } from "../../constants";
import { checkAuthorization } from "../../helpers";


const updateCreateApi = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const url = formData.id ? `/updateAndroidBox` : '/createAndroidBox';
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success('Android Box Created Successfully !!');
                return 1
            } else {
                message.error('Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};

const DeleteAndroidApi=(id)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteAndroidBox/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success('Android Box Created Successfully !!');
                return 1
            } else {
                message.error('Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
  }


const AddEditBoxApi = {
    updateCreateApi,
    DeleteAndroidApi
}

export {AddEditBoxApi}